import './TopHeaderAfterAuth.css';
import React, { useState, useEffect} from 'react';

import { useSelector} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import {IconCheckTrue, IconLogOut} from "../../IconComponents/IconComponents";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {RootState} from "../../store";
import Step3SocketModal from "../Modals/Step3ModalSocket/Step3SocketModal";
import Sidebar from "./Sidebar/Sidebar";
import {get} from "../../api/APIWraper";
import {User} from "../../types/ProjectTypes";
import {SidebarSearch} from "./Sidebar/IconsSidebar";

interface WebSocketMessage {
    data: {
        item: TItem;
        message: string;
    }[];
}

interface TItem {
    id: string;
    title: string;
    price: number;
    vendor: string;
    merchant_info?: {
        image?: string;
    };
    galleries?: {
        medias: { url: string }[];
    }[];
}

function TopHeaderAfterAuth() {
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const me = localStorage.getItem('Iam');
    const [user,setUser] = useState<User | null>(null);

    const [isSmallScreenMedium, setIsSmallScreenMedium] = useState<boolean>(window.innerWidth <= 1060);

    const handleResize = () => {
        setIsSmallScreenMedium(window.innerWidth <= 1060);
    };

    useEffect(() => {
        if (me) {
            try {
                const parsedUser: User = JSON.parse(me);
                setUser(parsedUser);
            } catch (error) {
                console.error("Failed to parse user data from localStorage:", error);
            }
        }
    }, [me]);
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleModalClose = () => {
        setModalOpen(false);
    };

    const [progress, setProgress] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [itemQuantity, setItemQuantity] = useState<number>(0);
    const [, setLoadedItems] = useState<number>(0);

    useEffect(() => {
        const ITEM_STORAGE_KEY = 'SocketItemModal';
        const QUANTITY_STORAGE_KEY = 'ItemQuantity';

        const updateProgress = () => {
            const itemQuantityStr = localStorage.getItem(QUANTITY_STORAGE_KEY);
            const socketItemsStr = localStorage.getItem(ITEM_STORAGE_KEY);

            if (!itemQuantityStr) {
                setProgress(0);
                setIsLoading(false);
                setItemQuantity(0);
                setLoadedItems(0);
                return;
            }

            const itemQuantityNum = parseInt(itemQuantityStr, 10);
            if (isNaN(itemQuantityNum) || itemQuantityNum === 0) {
                setProgress(0);
                setIsLoading(false);
                setItemQuantity(0);
                setLoadedItems(0);
                return;
            }

            if (!isLoading) {
                setIsLoading(true);
                setItemQuantity(itemQuantityNum);
            }

            let currentCount = 0;
            if (socketItemsStr) {
                try {
                    const messages = JSON.parse(socketItemsStr) as WebSocketMessage[];
                    currentCount = messages.reduce((acc, msg) => acc + msg.data.length, 0);
                } catch (err) {
                    console.error("Error when parsing SocketItemModal from localStorage:", err);
                    currentCount = 0;
                }
            }

            setLoadedItems(currentCount);
            const newProgress = Math.min((currentCount / itemQuantityNum) * 100, 100);
            setProgress(newProgress);

            if (newProgress >= 100) {
                setIsLoading(false);
            }
        };

        updateProgress();

        const interval = setInterval(updateProgress, 1000);

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === QUANTITY_STORAGE_KEY || event.key === ITEM_STORAGE_KEY) {
                updateProgress();
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            clearInterval(interval);
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [isLoading]);

    const handleLoaderClick = () => {
        setModalOpen(true);
    };

    if (!user || !user.galleries) {
        return null;
    }

    const userPhoto =
        user.galleries.length > 0 &&
        user.galleries[0]?.medias?.length > 0
            ? user.galleries[0].medias[user.galleries[0].medias.length - 1]?.url
            : '/img/user.png';

    const renderLoaderSocketModal = () => {
        return (
            isAuthenticated && itemQuantity > 0 && (
                <div
                    className="header-loader-container"
                    onClick={handleLoaderClick}
                    title={progress >= 100 ? "Open modal" : "Product receipt"}
                >
                    {isLoading ? (
                        <>
                            <div className="loader-socket-modal"></div>
                            <div className="loader-text-socket-modal">Loading {itemQuantity} products</div>
                        </>
                    ) : (
                        <div className="loader-complete">
                            <IconCheckTrue/>
                            <span className='loader-text-socket-modal'>{itemQuantity} products loaded</span>
                        </div>
                    )}
                    <div className="progress-bar-socket-modal">
                        <div
                            className="progress-bar-fill-socket-modal"
                            style={{width: `${progress}%`}}
                        ></div>
                    </div>
                </div>
            )
        )
    }

    return (
        <div
            className="top-header-after-auth">
            <div className='top-header-red-after-auth'>

                {!isSmallScreenMedium && (
                    renderLoaderSocketModal()
                )}
            </div>
            <div className='header-after-auth-container'>
                <div className="search-container">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="Search"
                    />
                    <button className="search-button">
                        <SidebarSearch/>
                    </button>
                </div>
                <div className='header-after-auth-user-block'>
                    <div className='user-block-img-container'>
                        <img
                            className='user-block-img'
                            src={userPhoto}
                            alt="User Profile"
                        />
                    </div>
                    <div className='user-block-name'>
                        <p className='user-block-username'>{user.username}</p>
                        <p className='user-block-email'>{user.email}</p>
                    </div>
                </div>
            </div>

            <Step3SocketModal
                isOpen={isModalOpen}
                onRequestClose={handleModalClose}
            />
            {isSmallScreenMedium && (
                <div className='top-header-red-mobile'>

                    {renderLoaderSocketModal()}
                </div>
            )}
        </div>
    );
}

export default TopHeaderAfterAuth;
