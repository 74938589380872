import React, {useEffect, useState} from "react";
import "./Sidebar.css";
import { IconLogOutRevers} from "../../../IconComponents/IconComponents";
import {
    Home,
    HomeActive,
    OrderHistory, OrderHistoryActive,
    ProjectList,
    ProjectListActive,
    Settings, SettingsActive, SidebarPlus, SidebarToggle, SidebarUpgradeCollapsed,
    Supports, SupportsActive,
    TeamManagement, TeamManagementActive
} from "./IconsSidebar";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useSelector} from "react-redux";
import {RootState} from "../../../store";
import CreateProjectModal from "../../Modals/ModalCreateProject/ModalCreateProject";

const Sidebar: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [currentPath, setCurrentPath] = useState(window.location.pathname);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const role = localStorage.getItem("role");
    const checkRole = role === 'Customer';
    const navigate = useNavigate();

    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);


    const toggleSidebar = () => {
        setIsExpanded((prev) => !prev);
    };

    const handleLogoClock = () => {
        if (isAuthenticated) {
            navigate('/assistance');
        } else {
            navigate('/home');
        }
    };

    const handleLogOut = () => {
        toast.info('Wait, we\'re coming out...');
        setTimeout(() => {
            sessionStorage.removeItem('Bearer');
            localStorage.removeItem('Bearer');
            localStorage.removeItem('role');
            localStorage.removeItem('oldRole')
            localStorage.removeItem('userId')
            localStorage.removeItem('Iam')
            navigate('/home');
            toast.success('You have successfully logged out!');
        }, 1000);
    };

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, [window.location.pathname]);

    const isActive = {
        '/assistance': currentPath === '/assistance',
        '/projects': currentPath === '/projects',
        '/team': currentPath === '/team' && !checkRole,
        '/order_history': currentPath === '/order_history',
        '/settings': currentPath === '/settings',
        '/support': currentPath === '/support',
    };

    return (
        <div className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
            <div
                title='Navigate to Assistance page'
                onClick={() => handleLogoClock()}
                className={`sidebar-logo ${isExpanded ? "expanded" : "collapsed"}`}>
                <img
                    src="/img_projects/gobuild.svg"
                    alt="Logo"
                    className="sidebar-logo-img"
                />
            </div>
            <div
                onClick={toggleSidebar}
                className='sidebar-toggle'>
                <SidebarToggle/>
            </div>
            <nav className={`sidebar-menu ${isExpanded ? "expanded" : "collapsed"}`}>
                <section>
                    <div
                        title='Navigate to Assistance page'
                        className={`${isActive['/assistance'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                        <a href="/assistance" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                            {isActive['/assistance'] ? <HomeActive/> : <Home/>}
                            <i/>
                            {isExpanded && <span
                                className={`text-path-sidebar ${isActive['/assistance'] ? "active-path" : ""}`}>Home</span>}
                        </a>
                    </div>
                    <div
                        title='Navigate to Project list page'
                        className={`${isActive['/projects'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                        <a href="/projects" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                            {isActive['/projects'] ? <ProjectListActive/> : <ProjectList/>}
                            <i/>
                            {isExpanded &&
                                <span className={`text-path-sidebar ${isActive['/projects'] ? "active-path" : ""}`}>Project list</span>}
                        </a>
                    </div>
                    {!checkRole && (
                        <div
                            title='Navigate to Team management page'
                            className={`${isActive['/team'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                            <a href="/team" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                                {isActive['/team'] ? <TeamManagementActive/> : <TeamManagement/>}
                                <i/>
                                {isExpanded &&
                                    <span className={`text-path-sidebar ${isActive['/team'] ? "active-path" : ""}`}>Team management</span>}
                            </a>
                        </div>
                    )}
                    <div
                        title='Navigate to Order history page'
                        className={`${isActive['/order_history'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                        <a href="/order_history" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                            {isActive['/order_history'] ? <OrderHistoryActive/> : <OrderHistory/>}
                            <i/>
                            {isExpanded && <span
                                className={`text-path-sidebar ${isActive['/order_history'] ? "active-path" : ""}`}>Order history</span>}
                        </a>
                    </div>
                    <div
                        title='Navigate to My account'
                        className={`${isActive['/settings'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                        <a href="/settings" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                            {isActive['/settings'] ? <SettingsActive/> : <Settings/>}
                            <i/>
                            {isExpanded && <span
                                className={`text-path-sidebar ${isActive['/settings'] ? "active-path" : ""}`}>Settings</span>}
                        </a>
                    </div>
                    <div
                        title='Navigate to Support page'
                        className={`${isActive['/support'] ? `icon-link-block ${isExpanded ? "expanded" : "collapsed"}` : ''}`}>
                        <a href="/support" className={`menu-item ${isExpanded ? "expanded" : "collapsed"}`}>
                            {isActive['/support'] ? <SupportsActive/> : <Supports/>}
                            <i/>
                            {isExpanded && <span
                                className={`text-path-sidebar ${isActive['/support'] ? "active-path" : ""}`}>Support</span>}
                        </a>
                    </div>

                    <div
                        title='Enhance the plan'
                        className={`sidebar-upgrade ${isExpanded ? "expanded" : "collapsed"}`}>
                        <div className={`sidebar-upgrade-block ${isExpanded ? "expanded" : "collapsed"}`}>
                            <div><SidebarUpgradeCollapsed/></div>
                            {isExpanded && <span className='sidebar-upgrade-text'>Upgrade now</span>}
                        </div>
                    </div>

                    <div
                        title='Create new project'
                        onClick={()=>handleOpenModal()}
                        className={`sidebar-create-project ${isExpanded ? "expanded" : "collapsed"}`}>
                        <div>
                            <SidebarPlus/>
                        </div>
                        {isExpanded && <span className="sidebar-create-project-text">Create new project</span>}
                    </div>
                </section>
            </nav>

            <CreateProjectModal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
            />

            <div onClick={handleLogOut} title='Log out' className="logout-button">
                <div className='logout-after-auth'><IconLogOutRevers/></div>
                {isExpanded && <span>Log Out</span>}
            </div>
        </div>

    );
};

export default Sidebar;
