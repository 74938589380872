import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import './style.css';
import {RootState} from "../../store";
import {connect, ConnectedProps} from "react-redux";
import PaginationAssistance from "../PaginationAssistance/pagination";
import ModalAddTemplate from "../ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {IconTemplateEyes, IconTemplatesAssistance} from "../../IconComponents/IconComponents";
import {getAllCategoriesTemplates} from "../../api/APIWraper";
import {CategoryTemplates} from "../../types/TemplateType";




const AsisTemplate: React.FC<PropsFromRedux> =
    ({ isAuthenticated}) => {
        const navigate = useNavigate();
        const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
        const [selectedTempId, isSelectedTempId] = useState<number>(0);
        const [selectedTempTitle, isSelectedTempTitle] = useState<string>('');
        const [categories, setCategories] = useState<CategoryTemplates[] | null>(null);

        const [currentPage, setCurrentPage] = useState(1);
        const templatesPerPage = 4;
        const [totalPages, setTotalPages] = useState(1);

        const infoTemplateToModal = (id: number, title: string) => {
            setIsModalOpen(true);
            isSelectedTempId(id);
            isSelectedTempTitle(title);
        };



        const fetchCategories = async (page: number) => {
            try {
                const { categories } = await getAllCategoriesTemplates(page, templatesPerPage);

                const maxPages = categories.reduce((max: number, category: { templates: { pagination: { lastPage: number; }; }; templateGroups: { pagination: { lastPage: number; }; }; }) => {
                    const templatesLastPage = category.templates.pagination.lastPage || 1;
                    const templateGroupsLastPage = category.templateGroups.pagination.lastPage || 1;
                    return Math.max(max, templatesLastPage, templateGroupsLastPage);
                }, 1);

                setTotalPages(maxPages);
                setCategories(categories);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        useEffect(() => {
            if (isAuthenticated) {
                fetchCategories(currentPage);
            } else {
                navigate("/auth/login");
            }
        }, [currentPage, isAuthenticated, navigate]);

        useEffect(() => {
            if (isModalOpen) {
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.overflow = 'unset';
            }
        }, [isModalOpen]);

        if (!categories) {
            return null
        }
        return (
            <>
                    <div>
                        <div className='template-name-content-assistance'>
                            <p>Templates</p>
                            <IconTemplatesAssistance/>
                        </div>
                        <div className='template-main'>
                            {categories.map(category => (
                                <div key={category.id}>
                                    {(category.templates.data.length > 0 || category.templateGroups.data.length > 0) && (
                                        <h2 className='template-title-text'>{category.category}</h2>
                                    )}
                                    <div className='template-main-container'>

                                        {category.templates.data.map(template => {
                                            const templateImage = template.galleries && template.galleries.length > 0 && template.galleries[0].medias.length > 0
                                                ? template.galleries[0].medias[template.galleries[0].medias.length - 1].url
                                                : '/img/house.jpg';

                                            return (
                                                <div className='template-project-list' key={template.id}>
                                                    <div onClick={() => navigate(`/template/${template.id}`)}
                                                         className='template-project-get'>
                                                        <div className='template-img-shadow' style={{
                                                            backgroundImage: `url(${templateImage})`
                                                        }}>
                                                            <IconTemplateEyes/>
                                                        </div>
                                                        <div className='template-title-block'>
                                                            <p className='template-title'>{template.title}</p>
                                                        </div>
                                                        <div className='view-details'>View details <span
                                                            className='view-details-arrow'></span></div>
                                                    </div>
                                                    <div className='template-project-title'>
                                                        <button
                                                            onClick={() => infoTemplateToModal(template.id, template.title)}
                                                            className='template-project-btn'>Add to a Project
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        {category.templateGroups.data.map(templateGroup => {
                                            const templateImage = templateGroup.galleries && templateGroup.galleries.length > 0 && templateGroup.galleries[0].medias.length > 0
                                                ? templateGroup.galleries[0].medias[templateGroup.galleries[0].medias.length - 1].url
                                                : '/img/house.jpg';

                                            return (
                                                <div className='template-project-list' key={templateGroup.id}>
                                                    <div onClick={() => navigate(`/template-group/${templateGroup.id}`)}
                                                         className='template-project-get'>
                                                        <div className='template-img-shadow' style={{
                                                            backgroundImage: `url(${templateImage})`
                                                        }}>
                                                            <IconTemplateEyes/>
                                                        </div>
                                                        <div className='template-title-block'>
                                                            <p className='template-title'>{templateGroup.title}</p>
                                                        </div>
                                                        <div className='view-details'>View details <span
                                                            className='view-details-arrow'></span></div>
                                                    </div>
                                                    <div className='template-project-title'>
                                                        <button
                                                            onClick={() => infoTemplateToModal(templateGroup.id, templateGroup.title)}
                                                            className='template-project-btn'>Add to a Project
                                                        </button>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                </div>
                            ))}

                        </div>



                        <PaginationAssistance
                            projectsPerPage={templatesPerPage}
                            totalProjects={totalPages * templatesPerPage}
                            paginate={(page) => setCurrentPage(page)}
                            currentPage={currentPage}
                        />

                        <ModalAddTemplate
                            isOpen={isModalOpen}
                            tempId={selectedTempId}
                            tempTitle={selectedTempTitle}
                            onClose={() => setIsModalOpen(false)}
                        />
                    </div>

            </>
        );
    };

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated
});


const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AsisTemplate);
